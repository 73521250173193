import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { graphql } from "gatsby";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { KindLink, KindIframe, MainPageRadio } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { removePreloader } from "../../helpers";
import { Share } from "../../widgets";

export const query = graphql`
	query Radio {
		hasura {
			radio {
				title_full
				description
				main_image
				source
				slug
				kind
				type
			}
		}
	}
`;

export default function Radio({ data }) {
	const radiostations = get(data, "hasura.radio", []);

	const radioListMap = {
		KindLink,
		KindIframe,
		MainPageRadio,
	};

	const url = "radio";

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages entity={get(seoData, "radio_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Радиостанции"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Радиовещание"}>
						{radiostations.map(radio => {
							return React.createElement(radioListMap[radio.kind], { ...radio });
						})}
					</TextBlock>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Православное радио"} />
			</div>
		</Pages>
	);
}

Radio.propTypes = {
	data: PropTypes.object,
};
